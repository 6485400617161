<template>
  <div class="toolbar">
    <a v-if="current != 'index'" class="item" href="javascript:;" @click="link('index')">
      <img src="/image/u344.svg" class="item-img"/>
      <span class="item-title item-title-default">首页</span>
    </a>
    <div v-if="current == 'index'" class="item">
      <img src="/image/u139.svg" class="item-img"/>
      <span class="item-title item-title-current">首页</span>
    </div>

    <!--
    <a v-if="current != 'message'" class="item" href="javascript:;" @click="link('message')" style="display: none;">
      <img src="/image/u132.svg" class="item-img"/>
      <span class="item-title item-title-default">消息</span>
    </a>
    <div v-if="current == 'message'" class="item" style="display: none;">
      <img src="/image/u132.png" class="item-img"/>
      <span class="item-title item-title-current">消息</span>
    </div>
    -->

    <a v-if="current != 'mine'" class="item" href="javascript:;" @click="link('mine')">
      <img src="/image/u136.svg" class="item-img"/>
      <span class="item-title item-title-default">我的</span>
    </a>
    <div v-if="current == 'mine'" class="item">
      <img src="/image/u518.svg" class="item-img"/>
      <span class="item-title item-title-current">我的</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Toolbar',
  props: {
    current: String
  },
  data() {
    return {};
  },
  methods: {
    link(target) {
      if (target == "index") {
        window.location.href = "mobile.html";
      } else if (target == "message") {
        window.location.href = "message.html";
      } else if (target == "mine") {
        window.location.href = "mine.html";
      }
    }
  }
}
</script>

<style scoped>
.toolbar {
  display: flex;
  border-top: 1px solid #E4E4E4;
  padding: 10px 0;
}

.item {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-img {
  width: 28px;
  height: 28px;
}

.item-title {
  font-size: 14px;
}

.item-title-default {
  color: #999999;
}

.item-title-current {
  color: #ee4b5f;
}
</style>