const exam = {
    state: {
        page: 'home',
        examSuiteList: [],
        appointmentDateList: [],
        examSuiteId: null,
        optionalItemIds: [],
        payMoney: 0,
    },
    mutations: {
        EXAM_BASE_DATA: (state, data) => {
            state.examSuiteList = data.examSuiteList;
            state.appointmentDateList = data.appointmentDateList;
        },
        CHANGE_PAGE: (state, data) => {
            state.page = data;
        },
        SELECT_SUITE: (state, data) => {
            state.page = data.page;
            state.examSuiteId = data.examSuiteId;
        },
        SELECT_OPTIONAL_ITEM: (state, data) => {
            state.page = data.page;
            state.optionalItemIds = data.optionalItemIds;
            state.payMoney = data.payMoney;
        },
    },
    actions: {}
};

export default exam;