<template>
  <div class="header">
    <img :src="getIcon()" class="img" @click="to"/>
    <span class="title">{{ title }}</span>
    <span>&nbsp;</span>
  </div>
</template>

<script>

export default {
  name: 'Header',
  props: {
    title: String,
    mode: {
      type: String,
      default: "back"
    }
  },
  data() {
    return {};
  },
  methods: {
    getIcon() {
      if (this.mode === "home") {
        return "/image/u315.png";
      } else {
        return "/image/u389.png";
      }
    },
    to() {
      if (this.mode === "home") {
        window.location.href = "mobile.html";
      } else if (this.mode === "callback") {
        this.$emit('callback');
      } else {
        window.history.back();
      }
    }
  }
}
</script>

<style scoped>
.header {
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img {
  width: 25px;
  height: 25px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #ee4b5f;
}
</style>