import constant from "@/store/mobile/constant";

export default {
    data() {
        return {
            // 接口状态
            apiStatus: this.$store.state.app.apiStatus,
        };
    },
    methods: {
        // 未登录
        unlogin(state) {
            if (!state) {
                state = "index";
            }
            let token = localStorage.getItem(constant.MOBILE_LOCAL_TOKEN);
            if (token) {
                return false;
            } else {

                window.location.href = "protocol.html?state=" + state;
                return true;
            }
        },
    }
};