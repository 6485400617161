<template>
  <div class="title">{{ title }}</div>
</template>

<script>

export default {
  name: 'Title',
  props: {
    title: String
  },
  data() {
    return {};
  },
  methods: {}
}
</script>

<style scoped>
.title {
  background-color: #ffffff;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #ee4b5f;
  text-align: center;
}
</style>