<template>
  <div class="patient">
    <img src="/image/u64.svg" style="width: 24px;height: 24px;"/>

    <div class="patient-info" v-if="$store.state.app.defaultPatient.patientId">
      <div style="font-size: 16px;">{{ $store.state.app.defaultPatient.patientName }}</div>
      <div style="font-size: 12px;padding-top: 5px;">NO.{{ $store.state.app.defaultPatient.cardNo }}</div>
    </div>
    <div class="patient-info" v-else>
      未添加就诊人
    </div>

    <a href="javascript:;" @click="changePatient" class="patient-btn"
       v-if="$store.state.app.defaultPatient.patientId">切换就诊人</a>
    <a href="javascript:;" @click="addPatient" class="patient-btn" v-else>添加就诊人</a>

    <ChangePatient ref="changePatient" @change="patientChanged"/>
  </div>
</template>

<script>

import ChangePatient from "./ChangePatient";

export default {
  name: 'Patient',
  components: {
    ChangePatient
  },
  data() {
    return {};
  },
  methods: {
    addPatient() {
      window.location.href = "patientmanage.html";
    },
    changePatient() {
      this.$refs.changePatient.show();
    },
    patientChanged() {
      this.$emit('change');
    },
  }
}
</script>

<style scoped>
.patient {
  background-color: #F6DEE1;
  border: 1px solid red;
  display: flex;
  padding: 5px 10px 5px 20px;
  align-items: center;
  border-radius: 10px;
}

.patient-info {
  flex-grow: 1;
  padding-left: 40px;
}

.patient-btn {
  background-color: #ffffff;
  color: #F59A23;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 12px;
}
</style>