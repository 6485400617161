const app = {
    state: {
        // 接口返回状态
        apiStatus: {
            fail: 0,
            success: 1,
            invalidToken: 2,
            defaultErrMsg: '操作异常！',
            defaultSuccMsg: '操作成功！'
        },
        // 分页对象
        pagination: {
            current: 1,
            pageSize: 20,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
            showTotal: total => `总共${total}条`,
            total: 0
        },
        // 默认就诊人
        defaultPatient: {},
    },
    mutations: {
        CHANGE_DEFAULT_PATIENT: (state, data) => {
            state.defaultPatient = data;
        },
    },
    actions: {}
};

export default app;