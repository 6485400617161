import {createStore} from 'vuex';

import app from './modules/app';
import register from './modules/register';
import exam from './modules/exam';

export default createStore({
    modules: {
        app,
        register,
        exam
    },
    state: {},
    mutations: {},
    actions: {},
    getters: {},
});
