import axios from 'axios';
import constant from "@/store/mobile/constant";

const instance = axios.create({
    baseURL: window._CONFIG['apiBaseUrl'],
    timeout: 30000
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // 请求携带token
    let token = localStorage.getItem(constant.MOBILE_LOCAL_TOKEN);
    if (token) {
        config.headers[constant.HEADER_TOKEN] = token;
    }

    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export {
    instance as axios
}
