<template>
  <van-popup v-model:show="showModel" position="top" style="height: 100%;">
    <div class="outer">
      <Header title="切换就诊人" mode="callback" @callback="close"/>
      <div class="add-line">
        <a class="add-btn" href="javascript:;" @click="add">+ 添加就诊人</a>
      </div>
      <div class="items">
        <div class="item" v-for="item in patientList" :key="item.patientId">
          <div class="item-info">
            <div class="item-name">
              <div>{{ item.patientName }}</div>

              <span v-if="item.gender == 1" style="padding-left: 10px;font-size: 14px;">男</span>
              <span v-else-if="item.gender == 2" style="padding-left: 10px;font-size: 14px;">女</span>

              <span style="padding-left: 10px;font-size: 14px;">{{ item.birthday }}</span>

              <span v-if="item.selfFlag == 1" class="self-flag">本人</span>
            </div>
            <div style="margin-top: 5px;">{{ item.cardNo }}</div>
          </div>
          <div class="item-opt">
            <img v-if="item.defaultFlag == 1" src="/image/c002.svg" style="width: 16px;height: 16px;"/>
            <img v-else src="/image/u600.svg" style="width: 16px;height: 16px;" @click="changeDefault(item)"/>

            <span style="padding-left: 10px;" v-if="item.defaultFlag == 1">默认</span>
            <span style="padding-left: 10px;" v-else>设为默认</span>
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>

import api from "@/api/mobile";
import CommonMixin from "@/mixin/mobile/CommonMixin";
import Header from "@/view/mobile/Header";

export default {
  name: 'ChangePatient',
  mixins: [CommonMixin],
  components: {
    Header
  },
  data() {
    return {
      showModel: false,
      patientList: [],
    };
  },
  methods: {
    show() {
      this.showModel = true;
      this.loadData();
    },
    loadData() {
      let that = this;
      api.patient.list().then(res => {
        if (res.code != that.$store.state.app.apiStatus.success) {
          that.$toast.fail("数据加载失败");
          return;
        }
        that.patientList = res.data;
      }).catch(() => {
        that.$toast.fail("数据加载失败");
      });
    },
    close() {
      this.showModel = false;
    },
    change() {
      this.close();
      this.$emit('change');
    },
    add() {
      window.location.href = "patientmanage.html";
    },
    changeDefault(patient) {
      let that = this;
      let loading = that.$toast.loading({
        forbidClick: true,
        duration: 0
      });
      api.patient.changeDefault(patient.patientId).then(res => {
        loading.clear();
        if (res.code != that.$store.state.app.apiStatus.success) {
          that.$toast.fail(res.message);
          return;
        }
        that.loadData();
        that.$store.commit("CHANGE_DEFAULT_PATIENT", {...patient});
        that.change();
      }).catch(() => {
        loading.clear();
        that.$toast.fail("操作失败");
      });
    }
  }
}
</script>

<style scoped>
.outer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.add-line {
  padding: 10px;
  border: 1px solid #E4E4E4;
  text-align: right;
}

.add-btn {
  color: #ee4b5f;
  font-size: 14px;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
}

.items {
  flex-grow: 1;
  background-color: #F9F9F9;
  padding: 0 10px;
  overflow: auto;
}

.item {
  margin-top: 10px;
  background-color: #F09EA8;
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
  font-size: 16px;
}

.item-info {
  border-bottom: 1px solid #ffffff;
  padding-bottom: 10px;
}

.item-name {
  display: flex;
  align-items: center;
}

.self-flag {
  font-size: 12px;
  padding: 2px 5px;
  margin-left: 10px;
  background-color: #ee4b5f;
  color: #ffffff;
  border-radius: 10px;
}

.item-opt {
  padding: 10px 10px 0 10px;
  display: flex;
  align-items: center;
}
</style>