import {axios} from './axios';

const postAction = (url, parm, timeout) => {
    let config = {
        url: url,
        method: 'post',
        data: parm ? parm : {}
    };
    if (timeout) {
        config.timeout = timeout;
    }
    return axios(config);
};

export default {
    wxjssdk: (parm) => postAction("/api/mobile/wxjssdk", parm),
    wxconfig: () => postAction("/api/mobile/wxconfig"),
    wxlogin: (parm) => postAction("/api/mobile/wxlogin", parm),
    getUserInfo: () => postAction("/api/mobile/getUserInfo"),
    loadPacsVisitData: () => postAction("/api/mobile/loadPacsVisitData"),

    register: {
        getRegisterBaseData: (parm) => postAction("/api/mobile/register/getRegisterBaseData", parm),
        validateVisitor: (parm) => postAction("/api/mobile/register/validateVisitor", parm),
        saveRegisterInfo: (parm) => postAction("/api/mobile/register/saveRegisterInfo", parm),
        allRegisterInfo: () => postAction("/api/mobile/register/allRegisterInfo"),
    },

    exam: {
        getExamBaseData: () => postAction("/api/mobile/exam/getExamBaseData"),
        getSelectTimeData: () => postAction("/api/mobile/exam/getSelectTimeData"),
        createOrder: (parm) => postAction("/api/mobile/exam/createOrder", parm),
        list: (parm) => postAction("/api/mobile/exam/list", parm),
        detail: (examInfoId) => postAction("/api/mobile/exam/detail/" + examInfoId),
        payOrder: (examInfoId) => postAction("/api/mobile/exam/payOrder/" + examInfoId),
        getPayResult: (examInfoId) => postAction("/api/mobile/exam/getPayResult/" + examInfoId),
        closeOrder: (examInfoId) => postAction("/api/mobile/exam/closeOrder/" + examInfoId),
        reportList: (parm) => postAction("/api/mobile/exam/reportList", parm),
    },

    patient: {
        list: () => postAction("/api/mobile/patient/list"),
        save: (parm) => postAction("/api/mobile/patient/save", parm),
        changeDefault: (patientId) => postAction("/api/mobile/patient/changeDefault/" + patientId),
        delete: (patientId) => postAction("/api/mobile/patient/delete/" + patientId),
        getDefault: () => postAction("/api/mobile/patient/getDefault"),
        getHisPatient: (hisPatientId) => postAction("/api/mobile/patient/getHisPatient/" + hisPatientId),
    },

    prescription: {
        unpayList: (parm) => postAction("/api/mobile/prescription/unpayList", parm),
        payedList: (parm) => postAction("/api/mobile/prescription/payedList", parm),
        detail: (parm) => postAction("/api/mobile/prescription/detail", parm),
        prepayOrder: (parm) => postAction("/api/mobile/prescription/prepayOrder", parm),
        getPayResult: (orderNo) => postAction("/api/mobile/prescription/getPayResult/" + orderNo),
    },

    lis: {
        list: (parm) => postAction("/api/mobile/lis/list", parm),
        pdf: (parm) => postAction("/api/mobile/lis/pdf", parm),
    },

    evaluation: {
        save: (parm) => postAction("/api/mobile/evaluation/save", parm),
    }
}
