<template>
  <div style="background-color: #ffffff;padding-bottom: 20px;">
    <img src="/image/u1592.svg" style="width: 100%;height: auto;"/>
    <div style="font-size: 14px;text-align: center;">
      暂无数据！
    </div>
  </div>
</template>

<script>

export default {
  name: 'Empty',
  data() {
    return {};
  },
  methods: {}
}
</script>
